@import "styles/layout.scss";
@import "styles/breakpoints.scss";

.container {
  @extend %lyt_row;
  width: 100%;
}

.parent {
  display: inline-block;
  line-height: 1;
  margin-bottom: 10px;
  color: #a0a0a0;

  @include media-breakpoint-up(xl) {
    cursor: pointer;
    padding-right: 24px;
    margin-right: 24px;
  }

  @include media-breakpoint-down(xl) {
    padding-right: 14px;
    margin-right: 14px;
  }
}

.parent_divider {
  border-right: 1px solid #ffffff;
}

.children {
  @extend %lyt_row;
  flex: 1;
  flex-wrap: wrap;
}

.child {
  display: inline-block;
  line-height: 1;
  margin-bottom: 9px;
  color: #ffffff;
  min-width: 56px;

  @include media-breakpoint-up(xl) {
    cursor: pointer;
    margin-right: 24px;
    transition: color 300ms ease-in-out;

    &:hover {
      color: #a0a0a0;
    }
  }

  @include media-breakpoint-down(xl) {
    margin-right: 14px;
  }
}

@import "styles/layout.scss";
@import "styles/breakpoints.scss";

.wrapper {
  @extend %lyt_abs-fill;
  @extend %lyt_column-c;
}

.container {
  @extend %lyt_column-c-v;
  position: relative;
  height: 100%;
  width: map-get($grid-breakpoints, xl);
}

.logo {
  @include img_wh(155px, 42px);
  position: absolute;
  left: 0;
}

.menu {
  @extend %lyt_row;
  height: 100%;
}

.digital {
  position: absolute;
  right: 0;
}

.menu_item {
  @extend %lyt_column-c-v;
  position: relative;
  height: 100%;
  min-width: 96px;
  padding: 0 30px;
  cursor: pointer;
  font-size: 16px;
  color: #ffffff;
  line-height: 100%;

  &:hover > ul {
    display: flex;
  }

  &:hover > .menu_indicator {
    opacity: 1;
    transform: translateX(-50%) scaleX(1);
  }
}

.menu_indicator {
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 2px;
  width: 48px;
  opacity: 0;
  transform: translateX(-50%) scaleX(0.25);
  transition: all 300ms ease-in-out;
  background-color: #ffffff;
}

.submenu {
  display: none;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 8px;
  width: 100%;
  height: 58px;
  transform: translateY(100%);
  background-color: #647968;
}

.submenu_item {
  @extend %lyt_column-c-v;
  position: relative;
  height: 100%;
  min-width: 108px;
  padding: 0 24px;
  cursor: pointer;
  font-size: 14px;
  line-height: 100%;
  color: #ffffff;
  transition: background-color 300ms ease-in-out;

  &:hover {
    background-color: #778e7c;
  }
}

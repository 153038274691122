@import "styles/layout.scss";
@import "styles/breakpoints.scss";

.container {
  @extend %lyt_row;
  flex-wrap: wrap;
  width: 100%;
}

.openInfo {
  display: flex;
  flex-direction: column;
  background-color: #778e7c;

  @include media-breakpoint-up(xl) {
    align-items: flex-end;
    justify-content: center;
    padding-right: 93px;
    height: 370px;
    width: calc(100% / 3);
  }

  @include media-breakpoint-down(xl) {
    width: 100%;
    align-items: center;
    padding: 30px 36px;
  }
}

.openInfo_title {
  font-weight: bold;
  color: #ffffff;
  line-height: 1.4;

  @include media-breakpoint-up(xl) {
    width: 276px;
    font-size: 30px;
    margin-bottom: 13px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 24px;
    margin-bottom: 8px;
  }
}

.openInfo_content {
  white-space: pre-wrap;
  color: #ffffff;
  line-height: 2;

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(xl) {
    width: 276px;
    font-size: 16px;
    letter-spacing: 1.16px;
    margin-bottom: 15px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 14px;
  }
}

.menuList {
  background-color: #f5f2ed;

  @include media-breakpoint-up(xl) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% / 3 * 2);
    height: 370px;
    padding-left: 155px;
  }

  @include media-breakpoint-down(xl) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px 14px;
  }
}

.videoPlayer__wrapper {
  background-color: rgb(34, 34, 34);
  width: 100%;
  height: 27.5vw;
}

.videoPlayer {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@import "styles/layout.scss";
@import "styles/breakpoints.scss";

.container {
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 18px;
    height: 1px;
    background-color: #ffffff;
    transition: all 300ms ease-in-out;
  }
}

.inactive {
  &::before {
    top: 0.5px;
    box-shadow: 0 8px #ffffff;
  }

  &::after {
    bottom: 0.5px;
  }
}

.active {
  &::before {
    top: 8.5px;
    box-shadow: none;
    transform: rotate(225deg);
  }

  &::after {
    bottom: 8.5px;
    transform: rotate(135deg);
  }
}

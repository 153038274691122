@import "styles/layout.scss";
@import "styles/breakpoints.scss";
@import "styles/transition.scss";

.modal {
  @include transition(visibility);
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;

  .overlay,
  .content {
    opacity: 0;
  }

  .overlay {
    @include transition(opacity);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.3);
  }

  .content {
    @include transition();
    position: absolute;
  }
}

.modal__active {
  visibility: visible;

  .overlay,
  .content {
    opacity: 1;
  }
}

// 中心-放大

.centerScale {
  .content {
    top: 50%;
    left: 50%;
    transform-origin: 0% 0%;
    transform: scale(0.5, 0.5) translate(-50%, -50%);
  }
}

.centerScale__active {
  .content {
    transform: scale(1, 1) translate(-50%, -50%);
  }
}

// 底部-滑出

.bottomUp {
  .content {
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(25%);
  }
}

.bottomUp__active {
  .content {
    transform: translateY(0%);
  }
}

// 中心-滑出

.centerSlide {
  .content {
    top: 50%;
    left: 50%;
    transform-origin: 0% 0%;
    transform: translate(-50%, -30%);
  }
}

.centerSlide__active {
  .content {
    transform: translate(-50%, -50%);
  }
}

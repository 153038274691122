@import "styles/layout.scss";
@import "styles/breakpoints.scss";
@import "styles/hairline.scss";

.container {
  @extend %lyt_row-c;
  width: 100%;
  border-bottom: 1px solid #ededed;

  @include media-breakpoint-up(xl) {
    height: 90px;
    margin-bottom: 36px;
  }

  @include media-breakpoint-down(xl) {
    height: 50px;
    margin-bottom: 18px;
  }
}

.label {
  color: #a2a2a2;
  margin: 0;
  line-height: 1;

  @include media-breakpoint-up(xl) {
    font-size: 16px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 14px;
  }
}

.arrow {
  @include img_wh(6px, 9px);

  @include media-breakpoint-up(xl) {
    padding: 0 10px;
  }

  @include media-breakpoint-down(xl) {
    padding: 0 6px;
  }
}

.arrow_word {
  font-size: 12px;
  color: #a2a2a2;
  line-height: 1;

  @include media-breakpoint-up(xl) {
    padding: 0 10px;
  }

  @include media-breakpoint-down(xl) {
    padding: 0 6px;
  }
}

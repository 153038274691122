@import "styles/layout.scss";
@import "styles/breakpoints.scss";

.container {
  @extend %lyt_row-c;
  @extend %lyt_abs-fill;
}

.logo {
  @extend %lyt_abs-c;
  @include img_wh(28px, 28px);
}

.digital {
  position: absolute;
  left: 10px;
}

.switch {
  position: absolute;
  right: 14px;
}
@import "styles/layout.scss";
@import "styles/breakpoints.scss";
@import "styles/hairline.scss";

.container {
  @extend %lyt_row-c;
  justify-content: space-between;
  padding: 0 10px;
  height: 30px;
  background-color: #ffffff;

  @include media-breakpoint-up(xl) {
    cursor: pointer;
    width: 180px;
  }

  @include media-breakpoint-down(xl) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.label {
  font-weight: bold;
  font-size: 12px;
  color: #222222;
}

.arrow {
  @include img_wh(6px, 9px);
}

.list {
  overflow-y: auto;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0px 2px 11px 0px rgba(75,75,75,0.5);

  @include media-breakpoint-up(xl) {
    width: 400px;
    max-height: 280px;
  }

  @include media-breakpoint-down(xl) {
    width: 350px;
    max-height: 240px;
  }
}

.item {
  @include hairline-bottom-relative(#DADADA);
  display: block;
  width: 100%;
  padding: 0 14px;
  text-align: center;
  font-weight: bold;

  &:nth-last-of-type(1) {
    @include hairline-bottom-relative(transparent);
  }

  @include media-breakpoint-up(xl) {
    height: 56px;
    line-height: 56px;
    font-size: 18px;
  }

  @include media-breakpoint-down(xl) {
    height: 48px;
    line-height: 48px;
    font-size: 14px;
  }
}
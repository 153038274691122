@mixin img_wh($width, $height) {
    width: $width;
    height: $height;
}

%lyt_abs-fill {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

%lyt_flex-fill {
    display: flex;
    flex: 1;
}

%lyt_column {
    display: flex;
    flex-direction: column;
}

%lyt_column-c {
    display: flex;
    flex-direction: column;
    align-items: center;
}

%lyt_column-c-v {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

%lyt_row {
    display: flex;
    flex-direction: row;
}

%lyt_row-c {
    display: flex;
    flex-direction: row;
    align-items: center;
}

%lyt_row-c-v {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

%lyt_ddd {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

%lyt_abs-c {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

%lyt_scroll-v {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

%lyt_scroll-h {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

@import "styles/layout.scss";
@import "styles/breakpoints.scss";
@import "styles/hairline.scss";

.container {
  @include hairline-bottom-relative(#647968);
  position: relative;
  overflow: hidden;
  width: 100%;
  transition: height 300ms ease-in-out;
}

.parent {
  @extend %lyt_row-c;
  justify-content: space-between;
  position: relative;
  padding: 0 14px;
  width: 100%;
  height: 60px;
}

.parent_title {
  font-size: 14px;
  color: #ffffff;
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.parent_title__focus {
  opacity: 0.65;
}

.parent_arrow {
  @include img_wh(6px, 9px);
  transform: rotate(0deg);
  transition: transform 300ms ease-in-out;
}

.parent_arrow__focus {
  transform: rotate(90deg);
}

.children {
  padding: 14px 28px;
  width: 100%;
  background-color: #647968;
}

.child {
  @extend %lyt_row-c;
  width: 100%;
  height: 40px;
}

.child_title {
  font-size: 14px;
  color: #ffffff;
}

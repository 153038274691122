@import "styles/layout.scss";
@import "styles/breakpoints.scss";

.wrapper {
  width: 100vw;
  height: 100%;
  background-color: #647968;

  @include media-breakpoint-up(xl) {
    height: 100vh;
  }

  @include media-breakpoint-down(xl) {
    height: 256px;
  }
}

.container {
  position: relative;
  width: 100vw;
  height: 100%;
}

.item {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.loading {
  @extend %lyt_abs-fill;
  opacity: 1;
  background-color: #647968;
  transition: opacity 300ms ease-in-out;
}

.loading__finished {
  opacity: 0;
}

.dot__wrapper {
  @extend %lyt_row-c-v;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;

  @include media-breakpoint-up(xl) {
    bottom: 28px;
  }

  @include media-breakpoint-down(xl) {
    bottom: 14px;
  }
}

.dot {
  border-radius: 50%;
  opacity: 0.5;
  background-color: #ffffff;
  transition: opacity 300ms ease-in-out;

  &:nth-last-of-type(1) {
    margin-right: 0;
  }

  @include media-breakpoint-up(xl) {
    cursor: pointer;
    width: 14px;
    height: 14px;
    margin-right: 20px;
  }

  @include media-breakpoint-down(xl) {
    cursor: pointer;
    width: 10px;
    height: 10px;
    margin-right: 12px;
  }
}

.dot__active {
  opacity: 1;
}
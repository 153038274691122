@import "styles/layout.scss";
@import "styles/breakpoints.scss";

.container {
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 44px;
  left: 0;
  visibility: hidden;
  transition: visibility 300ms ease-in-out;
}

.container__active {
  visibility: visible;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #778e7c;
  transition: opacity 300ms ease-in-out;
  opacity: 0;
}

.overlay__active {
  opacity: 1;
}

.content {
  @extend %lyt_scroll-v;
  width: 100%;
  height: calc(100% - 44px);
  transition: opacity 300ms ease-in-out;
  opacity: 0;
}

.content__active {
  opacity: 1;
}

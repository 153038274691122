@import "styles/layout.scss";
@import "styles/breakpoints.scss";

$grid-item-width: (map-get($grid-breakpoints, xl) - 60px) / 3;

.container {
  display: flex;
  width: 100%;

  @include media-breakpoint-up(xl) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @include media-breakpoint-down(xl) {
    flex-direction: column;
  }
}

.item {
  @include media-breakpoint-up(xl) {
    margin-right: 30px;
    margin-bottom: 40px;
    width: $grid-item-width;
    cursor: pointer;

    &:nth-of-type(3n) {
      margin-right: 0px;
    }
  }

  @include media-breakpoint-down(xl) {
    margin-bottom: 24px;
    width: 100%;
  }
}

.item_cover {
  margin-bottom: 10px;
  width: 100%;

  @include media-breakpoint-up(xl) {
    height: 178px;
  }

  @include media-breakpoint-down(xl) {
    height: 200px;
  }
}

.item_title {
  @extend %lyt_ddd;
  color: #262626;
  font-weight: bold;
  line-height: 1.4;


  @include media-breakpoint-up(xl) {
    font-size: 16px;
    margin-bottom: 10px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 14px;
    margin-bottom: 8px;
  }
}

.item_subtitle {
  @extend %lyt_ddd;
  color: #bdbdbd;
  line-height: 1.4;

  &:nth-last-of-type(1) {
    margin-bottom: 0px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 16px;
    margin-bottom: 10px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 14px;
    margin-bottom: 6px;
  }
}

@import "styles/layout.scss";
@import "styles/breakpoints.scss";

.title {
  color: #677b6c;
  font-weight: bold;
  line-height: 1.4;

  @include media-breakpoint-up(xl) {
    cursor: pointer;
    font-size: 30px;
    margin-right: 64px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.list {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(xl) {
    width: 100%;
  }
}

.item {
  @include media-breakpoint-up(xl) {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;

    &:nth-last-of-type(1) {
      margin-bottom: 0px;
    }
  }

  @include media-breakpoint-down(xl) {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #eee8e1;

    &:nth-last-of-type(1) {
      margin-bottom: 20px;
    }
  }
}

.item_title {
  @extend %lyt_ddd;
  color: #262626;

  @include media-breakpoint-up(xl) {
    cursor: pointer;
    margin-right: 63px;
    width: 336px;
    font-size: 16px;
    transition: color 300ms ease-in-out;

    &:hover {
      color: #999999;
    }
  }

  @include media-breakpoint-down(xl) {
    font-size: 14px;
    line-height: 1.5;
  }
}

.item_subtitle {
  color: #999999;

  @include media-breakpoint-up(xl) {
    font-size: 16px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 14px;
    line-height: 1.5;
  }
}

.more {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border: 1px solid #80aa9b;
  text-align: center;
  font-size: 14px;
  color: #778e7c;
}

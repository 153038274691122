@import "styles/layout.scss";
@import "styles/breakpoints.scss";

.container {
  width: 100%;
}

.cover {
  @include media-breakpoint-up(xl) {
    height: 256px;
  }

  @include media-breakpoint-down(xl) {
    height: 211px;
  }
}

.loading {
  @extend %lyt_abs-fill;
  opacity: 1;
  background-color: #778e7c;
  transition: opacity 300ms ease-in-out;
}

.loading__finished {
  opacity: 0;
}

.content {
  @include media-breakpoint-up(xl) {
    width: map-get($grid-breakpoints, xl);
    margin: 0 auto;
    padding-bottom: 50px;
  }

  @include media-breakpoint-down(xl) {
    width: 100%;
    padding: 0 14px;
    padding-bottom: 50px;
  }
}
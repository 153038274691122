@import "styles/layout.scss";
@import "styles/breakpoints.scss";

.container {
  @extend %lyt_row-c;
  width: 100%;

  @include media-breakpoint-up(xl) {
    flex-direction: row-reverse;
  }

  @include media-breakpoint-down(xl) {
    padding-top: 20px;
    justify-content: center;
  }
}

.item {
  width: 21px;
  height: 21px;
  margin-left: 15px;
  cursor: pointer;
}

.card {
  @extend %lyt_column-c;
  position: relative;
  width: 312px;
  max-width: 312px;
  padding: 28px 0px;
  box-shadow: 0px 2px 11px 0px rgba(75,75,75,0.5);
  background-color: #ffffff;
}

.card_caption {
  margin-top: 10px;
  max-width: 300px;
  text-align: center;
  white-space: pre-wrap;
  color: #222222;
  font-size: 14px;
}
@import "styles/layout.scss";
@import "styles/breakpoints.scss";

.container {
  @extend %lyt_column-c;
  width: 100%;
}

.container__disabled {
  opacity: 0.5;
  pointer-events: none;
}
$color-hairline: #636363;
$hairline-width: 1px;

@mixin hairline-common() {
    content: "";
    position: absolute;
    transform-origin: center;
    box-sizing: border-box;
    pointer-events: none;
}

@mixin hairline-base($color: $color-hairline, $style: solid) {
    @include hairline-common();

    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    border: 0 $style $color;
    transform: scale(0.5);
}

@mixin hairline-surround($color: $color-hairline, $style: solid, $width: $hairline-width) {
    position: relative;

    &::after {
        @include hairline-base($color, $style);

        border-width: $width;
    }
}

@mixin hairline-top($color: $color-hairline, $style: solid, $width: $hairline-width) {
    position: relative;

    &::after {
        @include hairline-base($color, $style);

        border-top-width: $width;
    }
}

@mixin hairline-bottom($color: $color-hairline, $style: solid, $width: $hairline-width) {
    position: relative;

    &::after {
        @include hairline-base($color, $style);

        border-bottom-width: $width;
    }
}

@mixin hairline-left($color: $color-hairline, $style: solid, $width: $hairline-width) {
    position: relative;

    &::after {
        @include hairline-base($color, $style);

        border-left-width: $width;
    }
}

@mixin hairline-right($color: $color-hairline, $style: solid, $width: $hairline-width) {
    position: relative;

    &::after {
        @include hairline-base($color, $style);

        border-right-width: $width;
    }
}

@mixin hairline-top-bottom($color: $color-hairline, $style: solid, $width: $hairline-width) {
    position: relative;

    &::after {
        @include hairline-base($color, $style);

        border-top-width: $width;
        border-bottom-width: $width;
    }
}

@mixin hairline-bottom-relative($color: $color-hairline, $style: solid, $width: $hairline-width, $left: 0) {
    position: relative;

    &::after {
        @include hairline-common();

        top: auto;
        left: $left;
        right: 0;
        bottom: 0;
        transform: scaleY(0.5);
        border-bottom: $width $style $color;
    }
}

@mixin hairline-top-relative($color: $color-hairline, $style: solid, $width: $hairline-width, $left: 0) {
    position: relative;

    &::before {
        @include hairline-common();

        top: 0;
        left: $left;
        right: 0;
        bottom: auto;
        transform: scaleY(0.5);
        border-top: $width $style $color;
    }
}

@mixin hairline-left-relative($color: $color-hairline, $style: solid, $width: $hairline-width, $top: 0) {
    position: relative;

    &::after {
        @include hairline-common();

        top: $top;
        left: 0;
        right: auto;
        bottom: 0;
        transform: scaleX(0.5);
        border-left: $width $style $color;
    }
}

@mixin hairline-right-relative($color: $color-hairline, $style: solid, $width: $hairline-width, $top: 0) {
    position: relative;

    &::after {
        @include hairline-common();

        top: $top;
        left: auto;
        right: 0;
        bottom: 0;
        transform: scaleX(0.5);
        border-right: $width $style $color;
    }
}

@import "styles/layout.scss";
@import "styles/breakpoints.scss";

.container {
  @extend %lyt_row;
  flex-wrap: wrap;
  width: 100%;
}

.item {
  position: relative;

  @include media-breakpoint-up(xl) {
    cursor: pointer;
    width: 25%;
    height: 370px;
  }

  @include media-breakpoint-down(xl) {
    width: 50%;
    height: 152px;
  }
}

.item_title {
  position: absolute;
  color: #ffffff;
  font-weight: bold;

  @include media-breakpoint-up(xl) {
    right: 45px;
    bottom: 45px;
    font-size: 36px;
  }

  @include media-breakpoint-down(xl) {
    right: 17px;
    bottom: 17px;
    font-size: 18px;
  }
}

.loading {
  @extend %lyt_abs-fill;
  opacity: 1;
  background-color: #647968;
  transition: opacity 300ms ease-in-out;
}

.loading__finished {
  opacity: 0;
}

@import "styles/layout.scss";
@import "styles/breakpoints.scss";

.container {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.25);
  transition: background-color 300ms ease-in-out;

  @include media-breakpoint-up(xl) {
    height: 66px;
  }

  @include media-breakpoint-down(xl) {
    height: 44px;
  }
}

.container__scrolled {
  background-color: #778e7c;
  border-bottom: 1px solid transparent;
}

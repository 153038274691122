@import "styles/layout.scss";
@import "styles/breakpoints.scss";

$masonry-pc-gap: 30px;
$masonry-mobile-gap: 14px;

.container {
  @extend %lyt_row;
  width: auto;

  @include media-breakpoint-up(xl) {
    margin-left: -$masonry-pc-gap;
  }

  @include media-breakpoint-down(xl) {
    margin-left: -$masonry-mobile-gap;
  }
}

.column {
  @extend %lyt_column;
  background-clip: padding-box;

  @include media-breakpoint-up(xl) {
    width: calc(100% / 3);
    padding-left: $masonry-pc-gap;
  }

  @include media-breakpoint-down(xl) {
    width: calc(100% / 2);
    padding-left: $masonry-mobile-gap;
  }
}

.item {
  width: 100%;

  @include media-breakpoint-up(xl) {
    cursor: pointer;
    margin-bottom: $masonry-pc-gap;
  }

  @include media-breakpoint-down(xl) {
    margin-bottom: $masonry-mobile-gap;
  }
}

.item_cover {
  margin-bottom: 10px;
}

.item_title {
  @extend %lyt_ddd;
  width: 100%;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;

  @include media-breakpoint-up(xl) {
    font-size: 14px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 18px;
  }
}

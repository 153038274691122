@import "styles/layout.scss";
@import "styles/breakpoints.scss";

.container {
  width: 100%;
}

.title {
  font-weight: bold;
  line-height: 1.4;

  @include media-breakpoint-up(xl) {
    font-size: 30px;
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 16px;
    margin-bottom: 16px;
  }
}

.label__wrapper {  
  width: 100%;
  border-bottom: 1px dashed #ededed;

  @include media-breakpoint-up(xl) {
    padding-bottom: 12px;
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(xl) {
    padding-bottom: 8px;
    margin-bottom: 10px;
  }
}

.label {
  color: #939393;

  @include media-breakpoint-up(xl) {
    font-size: 16px;
    margin-bottom: 12px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 14px;
    margin-bottom: 8px;
  }
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  // touch-action: none; /** 阻止ios端自带的滑动事件 */
  // user-select: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  box-sizing: border-box;
}

img {
  display: block;
  -webkit-touch-callout: none;
  user-select: none;
}

p {
  padding: 0;
  margin: 0;
  word-break: break-all;
}

a {
  color: inherit;
}

a,
button,
input {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0;
  background-color: transparent;
}

ol,
ul,
li,
nav,
header,
article,
main,
section,
footer,
div,
span,
a,
button {
  box-sizing: border-box;
}

ol,
ul,
li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

i {
  line-height: 1;
}

a,
button {
  cursor: pointer;
  user-select: none;
}

label {
  padding: 0;
  margin: 0;
  margin-right: 10px;
}

input,
textarea,
select {
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  outline: none;
  -webkit-appearance: none;
}

textarea {
  box-sizing: border-box;
  resize: none;
}

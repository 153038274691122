@import "styles/layout.scss";
@import "styles/breakpoints.scss";

.title__wrapper {
  width: 100%;

  @include media-breakpoint-up(xl) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 21px;
  }

  @include media-breakpoint-down(xl) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }
}

.title {
  color: #222222;

  @include media-breakpoint-up(xl) {
    font-size: 30px;
    line-height: 42px;
    margin-bottom: 15px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 18px;
    line-height: 27px;
  }
}

.subtitle {
  color: #bbbbbb;

  @include media-breakpoint-up(xl) {
    font-size: 16px;
    line-height: 21px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 14px;
    line-height: 27px;
  }
}
@import "styles/layout.scss";
@import "styles/breakpoints.scss";

.container {
  width: 100%;
}

.img {
  display: inline-block;
  max-width: 100% !important;
}
.container {
  padding: 3px;
  transition: all 200ms ease-in-out;
  background-color: #f5f2ed;
  border-radius: 17px;
  transform: translateY(-1px);
  box-shadow: 0px 3px 0px 0px #527259;
}

.container:hover {
  transform: translateY(0px);
  box-shadow: none;
}

.button {
  width: 82px;
  height: 28px;
  line-height: 28px;
  border: 1px solid #778e7c;
  text-align: center;
  border-radius: 14px;
  color: #778e7c;
  font-weight: bold;
}

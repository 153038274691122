@import 'styles/layout.scss';
@import 'styles/breakpoints.scss';
@import 'styles/hairline.scss';

.container {
  @extend %lyt_column-c;
  width: 100%;
  background-color: #2d2d2d;

  @include media-breakpoint-up(xl) {
    justify-content: space-between;
    padding-top: 30px;
    min-height: 300px;
  }
}

.flexWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blue {
  cursor: pointer;
}

.copyright {
  display: block;
  width: 100%;
  height: 100%;
  color: #ffffff;
  line-height: 1.5;
  text-align: center;

  @include media-breakpoint-up(xl) {
    font-size: 14px;
  }

  @include media-breakpoint-down(xl) {
    padding-top: 0px;
    font-size: 12px;
  }
}

.content {
  display: flex;

  @include media-breakpoint-up(xl) {
    flex-direction: row;
    align-items: stretch;
  }

  @include media-breakpoint-down(xl) {
    flex-direction: column;
    margin-top: 20px;
  }
}

.column {
  @include media-breakpoint-up(xl) {
    width: map-get($grid-breakpoints, xl) / 3;
  }

  @include media-breakpoint-down(xl) {
    @include hairline-bottom-relative(#4d4d4d);
    flex-direction: column;
    margin: 0px 14px 20px 14px;
    padding-bottom: 20px;
  }
}

.info_label {
  display: block;
  line-height: 18px;
  margin-bottom: 5px;
  color: #a0a0a0;
}

.info_content {
  display: inline-block;
  line-height: 21px;
  margin-bottom: 18px;
  color: #ffffff;

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
}

.column_first {
  @include media-breakpoint-up(xl) {
    padding-right: 28px;
  }
}

.column_last {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(xl) {
    align-items: flex-end;
    justify-content: space-between;
  }

  @include media-breakpoint-down(xl) {
    align-items: center;
  }
}

.logo {
  @include img_wh(155px, 42px);
}

@import "styles/layout.scss";
@import "styles/breakpoints.scss";

.container {
  width: 100%;
}

.item {
  @extend %lyt_row-c;
  width: 100%;
  background-color: #faf9f9;
  justify-content: space-between;

  @include media-breakpoint-up(xl) {
    cursor: pointer;
    padding: 30px 20px 30px 28px;
    margin-bottom: 30px;
    transition: box-shadow 300ms ease-in-out;

    &:hover {
      box-shadow: 0px 0px 8px 5px rgba($color: #111111, $alpha: 0.05);
    }
  }

  @include media-breakpoint-down(xl) {
    padding: 18px;
    margin-bottom: 18px;
  }
}

.item_title {
  color: #262626;

  @include media-breakpoint-up(xl) {
    font-size: 16px;
    margin-right: 30px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 14px;
    margin-right: 18px;
  }
}

.item_date {
  color: #9c9c9c;

  @include media-breakpoint-up(xl) {
    font-size: 16px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 14px;
  }
}
